a {
  color: white;
  text-decoration: none;
}

.App {
  background-color: #282c34;
  color: white;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.App-link {
  color: #61dafb;
}

#gamesContainer {
  text-align: left;
  margin-bottom: 2em;
  width: 100%;
  max-width: 375px;
}

.game {
  display: grid;
  grid-template-columns: 4fr 4fr 3fr 4fr 3fr;
  line-height: 1.5;
  padding: 0.5em 0;
  border-bottom: 1px solid #666;
}

.away,
.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.away,
.home,
.inning {
  padding: 0 0.5em;
}

.notInProgress {
  grid-column-start: 3;
  grid-column-end: span 3;
  text-align: center;
}

.bso {
  display: flex;
}

.inning,
.bso,
.notInProgress {
  font-size: 0.9em;
}
